/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import FileUpload from "react-mui-fileuploader";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import AppBar from "@mui/material/AppBar";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
// import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
// import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import DataTable from "examples/Tables/DataTable";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

import brand from "assets/images/logo.png";

const serverBaseURL = "https://analytics.enerscale.co/api/dbn/";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Stack
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      mt={3}
      justifyContent="center"
      {...other}
    >
      {value === index && children}
    </Stack>
  );
}

TabPanel.defaultProps = {
  children: "",
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function getSteps() {
  return ["Overview", "How to use"];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return (
        <MDBox mx={5}>
          <MDTypography variant="body2">
            EnerScale for PV Condition Tools quantifies degradation and soiling levels of your PV
            panels. Quantitative data results can be useful when determining your PV panel
            maintenance methods/frequencies and when assessing End-of-life options (reuse or
            recycling).
          </MDTypography>
        </MDBox>
      );
    case 1:
      return (
        <MDBox mx={5}>
          <MDTypography variant="body2">
            Please upload data (maximum 2 years) in our format to calculate degradation and soiling
            rates. We will analyze the uploaded data and show the following graphs and statistics:
          </MDTypography>
          <br />
          <MDTypography variant="body2">
            ・Aggregate:
            <br />
            irradiance weighted average
          </MDTypography>
          <br />
          <MDTypography variant="body2">
            ・Degradation calculation:
            <br />
            statistics including the 68.2% confidence interval and the P95 exceedance value
          </MDTypography>
          <br />
          <MDTypography variant="body2">
            ・Soiling calculations:
            <br />
            the soiling interval summary table, the 68.2 confidence interval for the
            insolation-weighted soiling ratio and the P50 insolation-weighted soiling ratio
          </MDTypography>
          <br />
        </MDBox>
      );
    default:
      return null;
  }
}

function Analytics() {
  const [tabValue, setTabValue] = useState(0);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const [timezone, setTimezone] = useState("Etc/GMT+7");
  const [inputTimezone, setInputTimezone] = useState("");
  const [gammaPdc, setGammaPdc] = useState(-0.005);
  const [powerDcRated, setPowerDcRated] = useState(1000.0);
  const [waitOpen, setWaitOpen] = useState(false);
  const [aggregateHelpOpen, setAggregateHelpOpen] = useState(false);
  const [degradationHelpOpen, setDegradationHelpOpen] = useState(false);

  const [aggregateData, setAggregateData] = useState({});
  const [sensorbasedDegradationData, setSensorbasedDegradationData] = useState({});
  const [confidenceIntervalData, setConfidenceIntervalData] = useState({});
  const [soilingProfileData, setSoilingProfileData] = useState({});
  const [soilingProfile2Data, setSoilingProfile2Data] = useState({});
  const [soilingIntervalData, setSoilingIntervalData] = useState({
    columns: [
      { Header: "Start", accessor: "start" },
      { Header: "End", accessor: "end" },
      { Header: "Soling rate", accessor: "soilingRate" },
      { Header: "Soling rate low", accessor: "soilingRateLow" },
      { Header: "Soling rate high", accessor: "soilingRateHigh" },
      { Header: "Inferred start loss", accessor: "inferredStartLoss" },
      { Header: "Inferred end loss", accessor: "inferredEndLoss" },
      { Header: "Length", accessor: "length" },
      { Header: "Valid", accessor: "valid" },
    ],
    rows: [],
  });
  const [soilingMonthlyData, setSoilingMonthlyData] = useState({
    columns: [
      { Header: "Month", accessor: "month" },
      { Header: "Soiling rate median", accessor: "soilingRateMedian" },
      { Header: "Soling rate low", accessor: "soilingRateLow" },
      { Header: "Soling rate high", accessor: "soilingRateHigh" },
      { Header: "Interval count", accessor: "intervalCount" },
    ],
    rows: [],
  });
  const [soilingAnnualData, setSoilingAnnualData] = useState({
    columns: [
      { Header: "Year", accessor: "year" },
      { Header: "Soling rate Median", accessor: "soilingRateMedian" },
      { Header: "Soling rate low", accessor: "soilingRateLow" },
      { Header: "Soling rate high", accessor: "soilingRateHigh" },
    ],
    rows: [],
  });
  const [histogramData, setHistogramData] = useState({});
  const [sr, setSr] = useState(0);
  const [srCi, setSrCi] = useState([0, 0]);
  const [exceedanceLevel, setExceedanceLevel] = useState(0);
  const [yoyRd, setYoyRd] = useState(0);
  const [yoyCi, setYoyCi] = useState([0, 0]);

  const handleFilesChange = (files) => {
    setFilesToUpload([...files]);
  };

  const timezoneOptions = [
    "Etc/GMT",
    "Etc/GMT+0",
    "Etc/GMT+1",
    "Etc/GMT+2",
    "Etc/GMT+3",
    "Etc/GMT+4",
    "Etc/GMT+5",
    "Etc/GMT+6",
    "Etc/GMT+7",
    "Etc/GMT+8",
    "Etc/GMT+9",
    "Etc/GMT+10",
    "Etc/GMT+11",
    "Etc/GMT+12",
    "Etc/GMT-0",
    "Etc/GMT-1",
    "Etc/GMT-2",
    "Etc/GMT-3",
    "Etc/GMT-4",
    "Etc/GMT-5",
    "Etc/GMT-6",
    "Etc/GMT-7",
    "Etc/GMT-8",
    "Etc/GMT-9",
    "Etc/GMT-10",
    "Etc/GMT-11",
    "Etc/GMT-12",
    "Etc/GMT-13",
    "Etc/GMT-14",
    "Etc/GMT0",
    "Etc/Greenwich",
    "Etc/UCT",
    "Etc/UTC",
    "Etc/Universal",
    "Etc/Zulu",
  ];

  const handleAggregateHelpOpen = () => setAggregateHelpOpen(true);
  const handleAggregateHelpClose = () => setAggregateHelpOpen(false);
  const handleDegradationHelpOpen = () => setDegradationHelpOpen(true);
  const handleDegradationHelpClose = () => setDegradationHelpOpen(false);

  const handleBack = () => setActiveStep(activeStep - 1);
  const handleForward = () => setActiveStep(activeStep + 1);

  const isLastStep = activeStep === steps.length - 1;

  const uploadFiles = async () => {
    // Create a form and post it to server
    setWaitOpen(true);
    const formData = new FormData();
    formData.append("timezone", timezone);
    formData.append("gamma_pdc", gammaPdc);
    formData.append("power_dc_rated", powerDcRated);
    filesToUpload.forEach((file) => formData.append("file", file));

    const result = await (
      await fetch(`${serverBaseURL}xxx_calc`, {
        method: "POST",
        body: formData,
      })
    ).json();

    console.log(result);
    setSr(result.res.sr);
    setSrCi(result.res.sr_ci);
    setExceedanceLevel(result.res.exceedance_level);
    setYoyRd(result.res.yoy_rd);
    setYoyCi(result.res.yoy_ci);

    setAggregateData({
      labels: result.res.aggregate_label,
      datasets: [
        {
          label: "",
          color: "dark",
          data: result.res.aggregate_data,
        },
      ],
    });

    setSensorbasedDegradationData({
      labels: result.res.degradation_renormalized_label,
      datasets: [
        {
          label: "",
          color: "dark",
          data: result.res.degradation_renormalized_data,
        },
      ],
    });
    setConfidenceIntervalData({
      labels: result.res.degradation_hist_label.map((i) => i.toFixed(3)),
      datasets: [
        {
          label: "",
          color: "dark",
          data: result.res.degradation_hist_data,
        },
      ],
    });

    const profiles = result.res.stochastic_soiling_profiles.map((i) => ({
      label: "",
      color: "dark",
      data: i,
    }));
    setSoilingProfileData({
      labels: result.res.renormalized_label,
      datasets: [
        {
          label: "",
          color: "dark",
          data: result.res.renormalized_data,
        },
        ...profiles,
      ],
    });

    setSoilingProfile2Data({
      labels: result.res.renormalized_label,
      datasets: [
        {
          label: "",
          color: "dark",
          data: result.res.renormalized_data,
        },
        {
          label: "",
          color: "dark",
          data: result.res.soiling_ratio_perfect_clean,
        },
      ],
    });

    setSoilingIntervalData({
      columns: [
        { Header: "Start", accessor: "start" },
        { Header: "End", accessor: "end" },
        { Header: "Soiling rate", accessor: "soilingRate" },
        { Header: "Soiling rate low", accessor: "soilingRateLow" },
        { Header: "Soiling rate high", accessor: "soilingRateHigh" },
        { Header: "Inferred start loss", accessor: "inferredStartLoss" },
        { Header: "Inferred end loss", accessor: "inferredEndLoss" },
        { Header: "Length", accessor: "length" },
        { Header: "Valid", accessor: "valid" },
      ],
      rows: result.res.soiling_interval_summary.map((i) => ({
        start: i[0],
        end: i[1],
        soilingRate: i[2].toFixed(3),
        soilingRateLow: i[3].toFixed(3),
        soilingRateHigh: i[4].toFixed(3),
        inferredStartLoss: i[5].toFixed(3),
        inferredEndLoss: i[6].toFixed(3),
        length: i[7],
        valid: i[8] ? "Yes" : "No",
      })),
    });
    setSoilingMonthlyData({
      columns: [
        { Header: "Month", accessor: "month" },
        { Header: "Soiling rate median", accessor: "soilingRateMedian" },
        { Header: "Soiling rate low", accessor: "soilingRateLow" },
        { Header: "Soiling rate high", accessor: "soilingRateHigh" },
        { Header: "Interval count", accessor: "intervalCount" },
      ],
      rows: result.res.monthly_soiling_rates.map((i) => ({
        month: i[0],
        soilingRateMedian: i[1].toFixed(3),
        soilingRateLow: i[2].toFixed(3),
        soilingRateHigh: i[3].toFixed(3),
        intervalCount: i[4],
      })),
    });
    setSoilingAnnualData({
      columns: [
        { Header: "Year", accessor: "year" },
        { Header: "Soiling rate Median", accessor: "soilingRateMedian" },
        { Header: "Soiling rate low", accessor: "soilingRateLow" },
        { Header: "Soiling rate high", accessor: "soilingRateHigh" },
      ],
      rows: result.res.annual_soiling_ratios.map((i) => ({
        year: i[0],
        soilingRateMedian: i[1].toFixed(3),
        soilingRateLow: i[2].toFixed(3),
        soilingRateHigh: i[3].toFixed(3),
      })),
    });
    setHistogramData({
      labels: result.res.hist_label.map((i) => i.toFixed(3)),
      datasets: [
        {
          label: "Sales by age",
          color: "dark",
          data: result.res.hist_data,
        },
      ],
    });
    setWaitOpen(false);
  };

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  useEffect(() => {}, []);

  const AggregateHelpButton = (
    <IconButton aria-label="help" onClick={handleAggregateHelpOpen}>
      <QuestionMarkIcon />
    </IconButton>
  );

  const DegradationHelpButton = (
    <IconButton aria-label="help" onClick={handleDegradationHelpOpen}>
      <QuestionMarkIcon />
    </IconButton>
  );

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `template.csv`;
    link.href = "./template.csv";
    link.click();
  };

  return (
    <div>
      <MDBox
        pt={2}
        sx={{
          backgroundColor: "#172B4D",
          height: "88px",
        }}
      >
        <Modal
          open={waitOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <MDBox p={5} color="white" bgColor="white" transform="translate(-50%, -50%)">
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Caculating, please wait.....
              </MDTypography>
            </MDBox>
          </MDBox>
        </Modal>
        <Modal
          open={aggregateHelpOpen}
          onClick={handleAggregateHelpClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <MDBox p={5} color="white" bgColor="white" transform="translate(-50%, -50%)">
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Data is aggregated with an irradiance weighted average. This
                <br />
                can be useful, for example with daily aggregation, to reduce
                <br />
                the impact of high-error data points in the morning and
                <br />
                evening.
              </MDTypography>
            </MDBox>
          </MDBox>
        </Modal>
        <Modal
          open={degradationHelpOpen}
          onClick={handleDegradationHelpClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <MDBox p={5} color="white" bgColor="white" transform="translate(-50%, -50%)">
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Data is then analyzed to estimate the degradation rate
                <br />
                representing the PV system behavior. The results are
                <br />
                visualized and statistics are reported, including the 68.2%
                <br />
                confidence interval, and the P95 exceedance value.
              </MDTypography>
            </MDBox>
          </MDBox>
        </Modal>
        <Grid container>
          <Grid item lg={3}>
            <MDBox component="img" src={brand} alt="Brand" width="12rem" />
          </Grid>
          <Grid item lg={6}>
            <MDTypography mt={2} variant="h4" align="center" sx={{ color: "#FFFFFF" }}>
              PV Condition Tools
            </MDTypography>
          </Grid>
          <Grid item lg={3}>
            <MDBox width="20rem" />
          </Grid>
        </Grid>
      </MDBox>
      <DashboardLayout>
        <MDBox py={3}>
          <MDBox mt={8} ml={35} mr={35}>
            <Card sx={{ height: "100%" }}>
              <MDBox mx={2} mt={-3}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={3}>
                <MDBox>
                  {getStepContent(activeStep)}
                  <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton variant="gradient" color="light" onClick={handleBack}>
                        back
                      </MDButton>
                    )}
                    {isLastStep ? (
                      <MDBox />
                    ) : (
                      <MDButton
                        type="submit"
                        variant="gradient"
                        color="dark"
                        onClick={handleForward}
                      >
                        next
                      </MDButton>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </MDBox>
          <MDBox mt={8}>
            <MDTypography mt={2} align="center" variant="h4" sx={{ color: "#4D1C68" }}>
              Please upload your performance data.
            </MDTypography>
          </MDBox>
          <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
            <MDButton variant="outlined" color="dark" onClick={onDownload}>
              <Icon>description</Icon>
              &nbsp;download template csv
            </MDButton>
          </MDBox>
          <Grid container>
            <Grid item lg={3} />
            <Grid item lg={3}>
              <MDBox mt={12} display="flex" justifyContent="center" alignItems="center">
                <MDBox width="20rem">
                  <Card sx={{ position: "relative", mt: -8, mx: 3, py: 2, px: 4 }}>
                    <Autocomplete
                      value={timezone}
                      onChange={(e, v) => setTimezone(v)}
                      inputValue={inputTimezone}
                      onInputChange={(e, v) => setInputTimezone(v)}
                      options={timezoneOptions}
                      renderInput={(params) => (
                        <MDInput {...params} variant="standard" label="Timezone" />
                      )}
                    />
                    <FormField
                      type="number"
                      label="gamma pdc"
                      defaultValue={gammaPdc}
                      onChange={(e) => setGammaPdc(e.target.value)}
                    />
                    <FormField
                      type="number"
                      label="power dc rated"
                      defaultValue={powerDcRated}
                      onChange={(e) => setPowerDcRated(e.target.value)}
                    />
                  </Card>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item lg={3}>
              <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
                <MDBox width="20rem">
                  <FileUpload
                    multiFile={false}
                    title="File to analysis"
                    onFilesChange={handleFilesChange}
                    onContextReady={() => {}}
                  />
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item lg={3} />
          </Grid>
          <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
            <MDButton variant="gradient" color="dark" onClick={uploadFiles}>
              Visualize result
            </MDButton>
          </MDBox>
          <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
            <Grid container spacing={3} alignItems="center">
              <Grid item md={3} lg={3}>
                <MDBox />
              </Grid>
              <Grid item md={6} lg={6}>
                <AppBar position="static">
                  <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                    <Tab
                      label="Aggregate"
                      icon={
                        <Icon fontSize="small" sx={{ mt: -0.25 }}>
                          workspaces
                        </Icon>
                      }
                    />
                    <Tab
                      label="Degradation calculation"
                      icon={
                        <Icon fontSize="small" sx={{ mt: -0.25 }}>
                          trending_down
                        </Icon>
                      }
                    />
                    <Tab
                      label="Soiling calculations"
                      icon={
                        <Icon fontSize="small" sx={{ mt: -0.25 }}>
                          report_problem
                        </Icon>
                      }
                    />
                  </Tabs>
                </AppBar>
              </Grid>
              <Grid item md={3} lg={3}>
                <MDBox />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <TabPanel value={tabValue} index={0}>
              <MDTypography mt={2} variant="h3" align="center" sx={{ color: "#484731" }}>
                Aggregate
              </MDTypography>
              <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
                <MDBox width="60rem">
                  <GradientLineChart
                    title="An irradiance weighted average"
                    chart={aggregateData}
                    help={AggregateHelpButton}
                  />
                </MDBox>
              </MDBox>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <MDTypography mt={2} variant="h3" align="center" sx={{ color: "#484731" }}>
                Degradation calculation
              </MDTypography>
              <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
                <MDBox width="60rem">
                  <Grid container spacing={20}>
                    <Grid item lg={6}>
                      <GradientLineChart
                        title="Sensor-based degradation results"
                        chart={sensorbasedDegradationData}
                        help={DegradationHelpButton}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <VerticalBarChart
                        title="The confidence interval"
                        chart={confidenceIntervalData}
                        help={DegradationHelpButton}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
              <MDBox display="flex" justifyContent="center" alignItems="center">
                <MDBox mt={4} width="40rem">
                  <Card sx={{ position: "relative", mx: 3, py: 1, px: 1 }} width="60rem">
                    <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        The P95 exceedance level
                      </MDTypography>
                      <MDBox ml={1}>
                        <MDTypography variant="body2" fontWeight="medium">
                          {exceedanceLevel.toFixed(3)}%/yr
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        The confidence level
                      </MDTypography>
                      <MDBox ml={1}>
                        <MDTypography variant="body2" fontWeight="medium">
                          {yoyRd.toFixed(3)}%/yr
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        The confidence interval
                      </MDTypography>
                      <MDBox ml={1}>
                        <MDTypography variant="body2" fontWeight="medium">
                          {yoyCi[0].toFixed(3)} to {yoyCi[1].toFixed(3)} %/yr
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </Card>
                </MDBox>
              </MDBox>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <MDTypography mt={2} variant="h3" align="center" sx={{ color: "#484731" }}>
                Soiling calculation
              </MDTypography>
              <MDBox display="flex" justifyContent="center" alignItems="center">
                <MDBox mt={4} width="40rem">
                  <Card sx={{ position: "relative", mx: 3, py: 1, px: 1 }} width="60rem">
                    <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        The P50 insolation-weighted soiling ratio:
                      </MDTypography>
                      <MDBox ml={1}>
                        <MDTypography variant="body2" fontWeight="medium">
                          {sr.toFixed(3)}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        The 68.2 confidence interval for the insolation-weighted soiling ratio
                      </MDTypography>
                      <MDBox ml={1}>
                        <MDTypography variant="body2" fontWeight="medium">
                          {srCi[0].toFixed(3)}-{srCi[1].toFixed(3)}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </Card>
                </MDBox>
              </MDBox>
              <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
                <MDBox width="60rem">
                  <Grid container spacing={20}>
                    <Grid item lg={6}>
                      <GradientLineChart
                        title="Plot Monte Carlo realizations of soiling profiles"
                        chart={soilingProfileData}
                        tyva="h6"
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <GradientLineChart
                        title="Plot the slopes for “valid” soiling intervals identified, assuming perfect cleaning events"
                        chart={soilingProfile2Data}
                        tyva="h6"
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
              <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
                <MDBox width="80rem">
                  <Card>
                    <MDBox p={3} lineHeight={1}>
                      <MDTypography variant="h5" fontWeight="medium">
                        The soiling interval summary table
                      </MDTypography>
                    </MDBox>
                    <DataTable table={soilingIntervalData} />
                  </Card>
                </MDBox>
              </MDBox>
              <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
                <VerticalBarChart title="Histogram of soiling rate" chart={histogramData} />
              </MDBox>
              <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
                <MDBox width="80rem">
                  <Card>
                    <MDBox p={3} lineHeight={1}>
                      <MDTypography variant="h5" fontWeight="medium">
                        A monthly soiling rate summary
                      </MDTypography>
                    </MDBox>
                    <DataTable table={soilingMonthlyData} />
                  </Card>
                </MDBox>
              </MDBox>
              <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
                <MDBox width="80rem">
                  <Card>
                    <MDBox p={3} lineHeight={1}>
                      <MDTypography variant="h5" fontWeight="medium">
                        Annual insolation-weighted soiling ratios and their confidence intervals
                      </MDTypography>
                    </MDBox>
                    <DataTable table={soilingAnnualData} />
                  </Card>
                </MDBox>
              </MDBox>
            </TabPanel>
          </MDBox>
        </MDBox>
      </DashboardLayout>
      <Footer />
    </div>
  );
}

export default Analytics;
